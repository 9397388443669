<template>
  <div>
    <v-list v-if="checkoutSteps !== null">
      <template v-for="checkoutStep in checkoutSteps">
        <checkoutStep
          :key="checkoutStep.namespace"
          :checkout-step="checkoutStep"
          :checkout-section-instances="checkoutSectionInstancesInStep(checkoutStep)"
        />
      </template>
    </v-list>
    <v-skeleton-loader
      v-else
      v-bind="attrs"
      type="list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
    />
  </div>
</template>

<script>

import checkoutStep from '@/components/checkout/checkoutStep'

export default {
    name: 'CheckoutSectionInstances',
    props: {
      channel: {
        type: Object,
        default: () => {}
      },
    },
    components: {
        checkoutStep,
    },
    data() {
        return {
            checkoutSectionInstances: null,
            fetchingCheckoutSectionInstances: false,
            checkoutSteps: null,
            fetchingCheckoutSteps: false
        }
    },
    computed: {
        activeCheckoutSectionInstances() {

        if(this.checkoutSectionInstances === null) {
          return null
        }

        return this.checkoutSectionInstances.filter(checkoutSectionInstance => checkoutSectionInstance.enabled)
      }
    },
    methods: {
        checkoutSectionInstancesInStep(step) {

            if(this.checkoutSectionInstances === null) {
                return null
            }

            return this.checkoutSectionInstances.filter(instance => instance.step === step.namespace)
        },
        getCheckoutSectionInstances() {
            this.fetchingCheckoutSectionInstances = true
            this.$store.dispatch('getCheckoutSectionInstancesByChannel', this.channel).then(checkoutSectionInstances => {
                this.checkoutSectionInstances = checkoutSectionInstances
            })
            .finally(() => {
                this.fetchingCheckoutSectionInstances = false
            })
        },
        getCheckoutSteps() {
            this.$store.dispatch('getCheckoutSteps', this.channel).then(checkoutSteps => {
                this.checkoutSteps = checkoutSteps
            })
        },
    },
    mounted() {
        this.getCheckoutSectionInstances()
        this.getCheckoutSteps()
    }
}
</script>
