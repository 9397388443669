<template>
  <v-container
    fill-height
    class="pt-0 pb-0"
    fluid
  >
    <v-row style="height: 100%">
      <v-col
        cols="4"
        style="height: 100%;"
      >
        <v-card height="100%">
          <v-card-title>{{ channel.label }}</v-card-title>
          <v-card-text
            v-if="false"
            class="pb-0"
          >
            <v-text-field
              small
              :label="$t('label')"
              outlined
              v-model="channel.label"
            />
            <v-text-field
              small
              :label="$t('description')"
              outlined
              v-model="channel.description"
            />
          </v-card-text>
          <CheckoutSectionInstances :channel="channel" />
        </v-card>
      </v-col>
      <v-col
        cols="8"
        class="pt-0 pr-0 pb-0"
        style="height: 100vh;"
      >
        <v-card
          tile
          height="100%"
        >
          <iframe
            width="100%"
            seamless
            :title="$t('preview')"
            style="border: 0; height: 100%;"
            :src="previewUrl"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CheckoutSectionInstances from '@/components/checkout/checkoutSectionInstances'
  export default {
    name: 'Channel',
    components: {
      CheckoutSectionInstances
    },
    data: () => ({
      channel: null,
      savingChannel: false
    }),
    computed: {
      organizationUUID() {
        return this.$store.state.organizationUUID
      },
      previewUrl() {
        return 'https://checkout.salescloud.is/?time=' + Date.now() + '#/' + this.organizationUUID + '/' + this.channel.uuid + '/checkout?sessionToken=1d35117c3112f4fb11e842c4176c6670475f6ee0239598e2fe941a434b9df07cf8a5b837a2f8510c30b595a1e22bf214a282810800b3aedec0cd8ac53c9c68de&language=en'
      }
    },
    methods: {
      saveChannel() {
        this.savingChannel = true
        this.$store.dispatch('updateChannel', this.channel).finally(() => {
          this.savingChannel = false
        })
      }
    },
    mounted() {
      this.channel = this.$store.state.channels.find(channel => channel.uuid === this.$route.params.uuid)
    }
  }
</script>
